import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import windowLogo from "../Assets/windowsLogo.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { blueGrey, lightGreen } from "@mui/material/colors";
import digiflowWindowFile from "../Assets/digiflowV1.zip";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 id="home-header" className="primary-heading">
            Digiflow Window Application
          </h1>
          <p className="primary-text">
            Click to download button and start to download. Unzip the download folder and run <i className="digiflow-exe">digiflow.exe</i> file.
          </p>
          {/* <a className="window-download-a" href={digiflowWindowFile} download="digiflow" target="_blank"> */}
          <button className="secondary-button" onClick={() => window.location.href='https://drive.google.com/file/d/1x7mqDqHOb_iOk0aiD8-lCa6Y7rnYztiE/view?usp=drive_link'}>
            Download Now <FiArrowRight />{" "}
          </button>
          {/* </a> */}
          
        </div>
        <div className="home-image-section">
          <img src={windowLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
