import React from "react";
// import PickMeals from "../Assets/pick-meals-image.png";
// import ChooseMeals from "../Assets/choose-image.png";
// import DeliveryMeals from "../Assets/delivery-image.png";
import { FiArrowRight } from "react-icons/fi";
import AppleLogo from "../Assets/applePhone.png"
import AndroidLogo from "../Assets/androidlogo2.png"
import { useNavigate } from "react-router-dom";



const Work = () => {
  const workInfoData = [
    {
      image: AppleLogo,
      title: "Iphone",
      text: "Click below button to go to Apple Store",
      link: "https://apps.apple.com/sg/app/digiflow-access/id1601797533",
    },
    {
      image: AndroidLogo,
      title: "Android",
      text: "Click below button to go to Play Store",
      link: "https://play.google.com/store/apps/details?id=com.appsheet.whitelabel.guid_0ae07a5e_f12b_4a2b_b3d6_ff9694866604&hl=en-SG",
    },
    // {
    //   image: DeliveryMeals,
    //   title: "Fast Deliveries",
    //   text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et lorem ipsum",
    // },
  ];
  // let navigate = useNavigate(); 
  // const routeChange = (path) =>{ 
  //   // let path = `newPath`; 
  //   navigate(path);
  // }
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Mobile Phone</p>
        <h1 className="primary-heading">Download</h1>
        {/* <p className="primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p> */}
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            {/* <h2>{data.title}</h2> */}
            <p>{data.text}</p>
            <button onClick={() => window.location.href=data.link} className="secondary-button">
              Download<FiArrowRight />{" "}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
